body {
    margin: 0;
}

.App {
    min-height: 100vh;
    background-color: #232323;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        padding: 10px;
    }

    .text-wrong-gallery {
        text-align: center;
        color: #545454;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.7rem;
        font-weight: 200;
    }

    .container-video {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        video {
            cursor: pointer;
            max-width: 100%;
            max-height: 80vh;
        }
    }
}
