.no-media {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 80%;
        max-width: 300px;
    }

    h2 {
        color: #e4e4e4;
        font-weight: 500;
        font-size: 2.3rem;
        letter-spacing: 0.05em;
        font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
        margin-top: 0.3rem;
    }
}
