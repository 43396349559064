.grid-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.buttons-gallery {
    justify-content: flex-end;
}

.buttons-modal {
    justify-content: center;
}
