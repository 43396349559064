.spinner-home {
    border: 4px solid #232323;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #d3d3d3;

    animation: spin 1s ease infinite;
}

.button-share .spinner-home {
    width: 16px;
    height: 16px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

