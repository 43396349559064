.button-share {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 10px;
    margin: 5px;
    background-color: #d3d3d3;
    transition: 0.2s;

    @include sm {
        width: 40px;
        height: 40px;
        padding: 7px;
    }

    &:hover {
        cursor: pointer;
        background-color: #4d7179;
        transition: 0.2s;
    }

    img {
        width: 24px;
        height: auto;
    }
}

#popover-email {
    z-index: 9999;
}

.popover-content {
    position: fixed;
    background-color: #ccc;
    padding: 15px;
    border-radius: 4px;
    top: 0px;
    left: -320px;
    font-family: Arial, Helvetica, sans-serif;
    transition: 0.8s;

    .popover-message {
        margin: 0;
    }

    @keyframes transition {
        0% {
            left: -300px;
        }
        20% {
            left: 0px;
        }
        80% {
            left: 0px;
        }
        100% {
            left: -300px;
        }
    }
}
